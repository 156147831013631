@use 'core-variables/base-theme-core-variables' as core-variables;

/* Dark Mint Theme */

.light-blue-theme {
  @include core-variables.core-theme-variables;

  --light_imagotipo: #009342;

  /* Main */
  --main: #0066ff;
  --main80: #0049b6;
  --main90: #0057db;
  --main10: #dbe9ff;
}
