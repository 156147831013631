@use '../components-variables/base-theme.components' as components;

/* Base theme variables */
@mixin core-theme-variables() {
  // NEW VARIABLES

  /*Imagotipo ICM variable */
  --light_imagotipo: #009342;
  /* Main */
  --main: #0f803e;
  --main80: #0d6e35;
  --main90: #0b5b2c;
  --main10: #daf2e0;

  /* Base */
  --base10: #f2f4f8;
  --base20: #d5d9e0;
  --base30: #b9bfc7;
  --base: #50565b;
  --base80: #373d42;
  --base90: #242a2e;
  --base100: #13171a;

  /* Accent */
  --accent: #923fff;
  --accent05: #f6efff;
  --accent10: #eddfff;
  --accent30: #c99fff;
  --accent40: #b67fff;
  --accent80: #6e1bdb;

  /* On Surface */
  $surface: #000000;
  --onSurface_High: #{rgba($surface, 0.87)};
  --onSurface_Medium: #{rgba($surface, 0.6)};
  --onSurface_Low: #{rgba($surface, 0.38)};
  --onSurface_ExtraLow: #{rgba($surface, 0.15)};

  /* On Color Surface */
  $colorSurface: #ffffff;
  --onColorSurface_High: #{rgba($colorSurface, 1)};
  --onColorSurface_Medium: #{rgba($colorSurface, 0.74)};
  --onColorSurface_Low: #{rgba($colorSurface, 0.38)};

  /* Info */
  --info05: #edf4ff;
  --info10: #dbe9ff;
  --info70: #0066ff;
  --info80: #0057db;

  /* Warning */
  --warning10: #fcebdb;
  --warning80: #ca6303;
  --warning90: #a95203;

  /* Alert */
  --alert05: #fdedef;
  --alert10: #fcdbdf;
  --alert20: #f9b6bf;
  --alert30: #f6929f;
  --alert70: #e9001f;
  --alert: #c8001b;
  --alert90: #a60016;
  --alert100: #850012;

  /* Success */
  --success05: #edf9f0;
  --success10: #daf2e0;
  --success20: #b5e5c1;
  --success30: #95cfa8;
  --success60: #31925a;
  --success80: #0d6e35;

  /* Background */
  --background: #ffffff;
  --background10: #f2f4f8;
  --background20: #d5d9e0;
  --background30: #b9bfc7;
  --background40: #9fa5ad;

  /* Range */
  /* Black */
  --range01: #000000;
  /* White */
  --range02: #ffffff;
  /* Yellow */
  --range03_05: #fefbed;
  --range03_10: #fcf7db;
  --range03_20: #faefb7;
  --range03_30: #f7e793;
  --range03_40: #f4df70;
  --range03_50: #f1d74c;
  --range03_60: #efcf28;
  --range03_70: #ecc704;
  --range03_80: #caab03;
  --range03_90: #a98e03;
  --range03_100: #877202;

  /* Orange */
  --range04_05: #fef5ed;
  --range04_10: #fcebdb;
  --range04_20: #fed5b8;
  --range04_30: #f7c393;
  --range04_40: #f4af70;
  --range04_50: #f19b4c;
  --range04_60: #ef8728;
  --range04_70: #ec7304;
  --range04_80: #ca6303;
  --range04_90: #a95203;
  --range04_100: #874202;

  /* Red */
  --range05_05: #fdedef;
  --range05_10: #fcdbdf;
  --range05_20: #f9b6bf;
  --range05_30: #f6929f;
  --range05_40: #f26d7f;
  --range05_50: #ef495f;
  --range05_60: #ec243f;
  --range05_70: #e9001f;
  --range05_80: #c8001b;
  --range05_90: #a60016;
  --range05_100: #850012;

  /* Magenta */
  --range06_05: #fdedf1;
  --range06_10: #fbdbe3;
  --range06_20: #f8b6c7;
  --range06_30: #f492ab;
  --range06_40: #f06d90;
  --range06_50: #ec4974;
  --range06_60: #e92458;
  --range06_70: #e5003c;
  --range06_80: #c40033;
  --range06_90: #a4002b;
  --range06_100: #830022;

  /* Purple */
  --range07_05: #f6efff;
  --range07_10: #eddfff;
  --range07_20: #dbbfff;
  --range07_30: #c99fff;
  --range07_40: #b67fff;
  --range07_50: #a45fff;
  --range07_60: #923fff;
  --range07_70: #801fff;
  --range07_80: #6e1bdb;
  --range07_90: #5b16b6;
  --range07_100: #491292;

  /* Blue */
  --range08_05: #edf4ff;
  --range08_10: #dbe9ff;
  --range08_20: #b6d3ff;
  --range08_30: #92bdff;
  --range08_40: #6da8ff;
  --range08_50: #4992ff;
  --range08_60: #247cff;
  --range08_70: #0066ff;
  --range08_80: #0057db;
  --range08_90: #0049b6;
  --range08_100: #003a92;

  /* Cyan */
  --range09_05: #edf5f9;
  --range09_10: #dbecf3;
  --range09_20: #b6d9e8;
  --range09_30: #92c6dc;
  --range09_40: #6db2d0;
  --range09_50: #499fc4;
  --range09_60: #248cb9;
  --range09_70: #0079ad;
  --range09_80: #006894;
  --range09_90: #00567c;
  --range09_100: #004563;

  /* Aqua */
  --range10_05: #edf6f6;
  --range10_10: #dbedee;
  --range10_20: #b6dcdd;
  --range10_30: #92cacc;
  --range10_40: #6db9ba;
  --range10_50: #49a7a9;
  --range10_60: #249698;
  --range10_70: #008487;
  --range10_80: #007174;
  --range10_90: #005e60;
  --range10_100: #004b4d;

  /* Green */
  --range11_05: #edf9f0;
  --range11_10: #daf2e0;
  --range11_20: #b5e5c1;
  --range11_30: #95cfa8;
  --range11_40: #74ba8e;
  --range11_50: #54a475;
  --range11_60: #31925a;
  --range11_70: #0f803e;
  --range11_80: #0d6e35;
  --range11_90: #0b5b2c;
  --range11_100: #094923;

  /* Grey */
  --range12_05: #f8f9fb;
  --range12_10: #f2f4f8;
  --range12_20: #d5d9e0;
  --range12_30: #b9bfc7;
  --range12_40: #9fa5ad;
  --range12_50: #868d95;
  --range12_60: #697077;
  --range12_70: #50565b;
  --range12_80: #373d42;
  --range12_90: #242a2e;
  --range12_100: #13171a;

  /*Shadows*/
  --shadowL1: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --shadowL2: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --shadowL3: 0px 5px 9px 4px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  --shadowL4: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.3);

  // OLD VARIABLES

  /* Interactive */
  --interactive01: var(--main);
  --interactive02: var(--base);
  --interactive03: var(--accent);

  /* Destructive */
  --destructive01: var(--alert);

  /* State */
  --active01: var(--main90);
  --active02: var(--base90);
  --active03: var(--alert100);
  --activeUi: var(--onSurface_ExtraLow);

  --hover01: var(--main80);
  --hover02: var(--base80);
  --hover03: var(--base30);
  --hover04: var(--base10);
  --hover05: var(--alert90);

  --disabled01: var(--base10);
  --disabled02: var(--onSurface_ExtraLow);
  --disabled03: var(--base30);
  --disabled04: var(--onSurface_Low);

  /* Support */
  --support01: var(--alert70);
  --support02: var(--success60);
  --support03: var(--warning80);
  --support04: var(--info70);
  --support05: var(--accent);

  --supportBack01: var(--alert05);
  --supportBack02: var(--success05);
  --supportBack03: var(--warning10);
  --supportBack04: var(--info05);
  --supportBack05: var(--accent10);
  --supportBack011: var(--alert10);
  --supportBack012: var(--alert20);
  --supportBack013: var(--alert30);
  --supportBack022: var(--success20);
  --supportBack023: var(--success30);
  --supportBack041: var(--info10);
  --supportBack051: var(--accent30);
  --supportBack052: var(--accent40);

  /* Text */
  --text01: var(--base100);
  --text02: var(--base80);
  --text03: var(--onSurface_Medium);
  --text04: var(--base30);
  --text05: var(--onColorSurface_High);
  --text06: var(--main);
  --textError: var(--alert);
  --textWarning: var(--warning90);

  /* Icon */
  --icon01: var(--base100);
  --icon02: var(--base80);
  --icon03: var(--onSurface_Medium);
  --icon04: var(--base30);
  --icon05: var(--onColorSurface_High);

  /* UI */
  --ui01: var(--base100);
  --ui02: var(--onSurface_Medium);
  --ui03: var(--base30);
  --ui04: var(--base10);
  --ui05: var(--background);
  --ui06: var(--base80);

  /* Field */
  --field01: var(--background);
  --field02: var(--background10);
  --field03: var(--main10);
  --field04: var(--background20);
  --field05: var(--background30);
  --field06: var(--background30);

  /* Highlight */
  --highlight01: var(--main);
  --highlight02: var(--main10);

  /* Shadows */
  --dropShadow01: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --dropShadow02: 0px -1px 4px rgba(0, 0, 0, 0.25);
  --dropShadow03: 0px 0px 4px rgba(0, 0, 0, 0.25);
  --lightShadow01: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  --lightShadow02: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  --popUpShadow01: 0 4px 20px rgba(0, 0, 0, 0.25);
  --popUpShadow02: -4px 0px 8px rgba(0, 0, 0, 0.25);
  --popUpShadow03: 4px 0px 8px rgba(0, 0, 0, 0.25);

  /* Weight */
  --fontWeightLight: 300;
  --fontWeightSemibold: 600;
  --fontWeightRegular: 400;

  /* Font */
  --fontSizeHelper: 0.75rem; // 12px
  --fontSizeCaption: 0.75rem; // 12px
  --fontSizeOverline: 0.75rem; // 12px
  --fontSizeBody01: 1.125rem; // 18px
  --fontSizeBody02: 1rem; // 16px
  --fontSizeBody03: 0.875rem; // 14px
  --fontSizeHeading01: 2.625rem; // 42px
  --fontSizeHeading02: 2.25rem; // 36px
  --fontSizeHeading03: 2rem; // 32px
  --fontSizeHeading04: 1.75rem; // 28px
  --fontSizeHeading05: 1.5rem; // 24px
  --fontSizeHeading06: 1.25rem; // 20px
  --fontSizeDisplay01: 3.75rem; // 60px
  --fontSizeDisplay02: 3.375rem; // 54px
  --fontSizeDisplay03: 3rem; // 48px

  /* LineHeight */
  --lineHeightHelper: 1rem; // 16px
  --lineHeightCaption: 1rem; // 16px
  --lineHeightOverline: 1rem; // 16px
  --lineHeightBody01: 1.5rem; // 24px
  --lineHeightBody02: 1.375rem; // 22px
  --lineHeightBody03: 1.188rem; // 19px
  --lineHeightHeading01: 3.563rem; // 57px
  --lineHeightHeading02: 3.063rem; // 49px
  --lineHeightHeading03: 2.688rem; // 43px
  --lineHeightHeading04: 2.375rem; // 38px
  --lineHeightHeading05: 2.063rem; // 33px
  --lineHeightHeading06: 1.688rem; // 27px
  --lineHeightDisplay01: 5.125rem; // 82px
  --lineHeightDisplay02: 4.563rem; // 73px
  --lineHeightDisplay03: 4.063rem; // 65px

  @include components.component-variables;
}
