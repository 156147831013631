@mixin component-variables() {
  /* BODY */
  --bodyColor: var(--onSurface_Medium);

  /* SCROLLBAR */
  --scrollbarTrackBackground: var(--background10);
  --scrollbarThumbBackground: var(--background20);

  /* ALERT */
  --alertBackgroundOutline: var(--background);
  --alertDescriptionColorDefault: var(--onSurface_Medium);
  --alertDescriptionColorOutline: var(--onSurface_Medium);

  --alertTitleIconColorAlert: var(--alert70);
  --alertTitleIconColorOutlineAlert: var(--alert70);
  --alertBackgroundAlert: var(--alert05);
  --alertOutlinePropertyColorAlert: transparent;

  --alertTitleIconColorWarning: var(--warning80);
  --alertTitleIconColorOutlineWarning: var(--warning80);
  --alertBackgroundWarning: var(--warning10);
  --alertOutlinePropertyColorWarning: transparent;

  --alertTitleIconColorInfo: var(--info70);
  --alertTitleIconColorOutlineInfo: var(--info70);
  --alertBackgroundInfo: var(--info05);
  --alertOutlinePropertyColorInfo: transparent;

  /* BADGE */
  --badgeBackgroundInfo: var(--info70);
  --badgeBackgroundSuccess: var(--success60);

  /* BUTTON */
  --buttonHoverMain: var(--main80);
  --buttonActiveMain: var(--main90);

  --buttonHoverBase: var(--base80);
  --buttonActiveBase: var(--base90);

  --buttonHoverDestructive: var(--alert90);
  --buttonActiveDestructive: var(--alert100);

  --buttonBackgroundWarning: var(--base);
  --buttonHoverWarning: var(--base80);
  --buttonActiveWarning: var(--base90);

  /* BUTTON TOGGLE */
  --buttonToggleBackground: var(--background10);
  --buttonToggleBackgroundDisabled: var(--onSurface_ExtraLow);

  /* CARD */
  --cardBackground: var(--background);
  --cardHoverBackground: var(--base10);
  --cardBackgroundSelected: var(--accent05);
  --cardHoverBackgroundSelected: var(--base10);

  --cardBackgroundDragAndDrop: var(--base10);
  --cardBorderColorDragAndDrop: var(--onSurface_Medium);
  --cardIconDragColor: var(--onSurface_ExtraLow);

  /* LINK */

  --linkColorSecundary: var(--onSurface_Medium);
  --linkColorAlert: var(--alert70);
  --linkColorInfo: var(--info70);
  --linkColorWhite: var(--onColorSurface_High);
  --linkColorFeatured: var(--accent30);
  --linkColorDisabled: var(--onSurface_ExtraLow);

  /* CHECKBOX */
  --checkboxDisabledIconColor: var(--onColorSurface_High);
  --checkboxBorderColor: var(--onSurface_Low);
  --checkboxDisabledBackgroundColorEmpty: var(--onColorSurface_High);
  --checkboxColorHover: var(--main80);

  /* CHIP */
  --chipSoftBackgroundColorAccent: var(--accent10);
  --chipSoftColorSuccess: var(--success80);
  --chipSoftColorNeutral: var(--base80);
  --chipSoftColorWarning: var(--warning90);
  --chipSoftColorAccent: var(--accent80);
  --chipSoftColorAlert: var(--alert);
  --chipSoftColorInfo: var(--info80);

  // Tag mode
  --chipBackgroundColorTagSuccess: var(--success60);
  --chipBackgroundColorTagInfo: var(--info70);

  // Error Flat
  --chipColorFlatError: var(--onColorSurface_High);
  --chipBackgroundFlatError: var(--alert);
  --chipBorderFlatError: var(--alert);
  --chipColorFlatHoverError: var(--onColorSurface_High);
  --chipBackgroundFlatHoverError: var(--alert90);
  --chipBorderFlatHoverError: var(--alert90);

  // Error Outline
  --chipColorOutlineError: var(--alert);
  --chipBackgroundOutlineError: var(--alert05);
  --chipBorderOutlineError: var(--onSurface_ExtraLow);
  --chipIconColorOutlineError: var(--onSurface_Medium);
  --chipColorOutlineHoverError: var(--alert);
  --chipBackgroundOutlineHoverError: var(--alert10);
  --chipBorderOutlineHoverError: var(--onSurface_ExtraLow);

  // Input and Choice - Flat
  --chipBackgroundFlatInputChoiceHover: var(--main80);
  --chipBackgroundFlatInputChoiceSelected: var(--main90);
  --chipBackgroundFlatInputChoiceHoverSelected: var(--base80);

  // Input and Choice - Outline
  --chipColorOutlineInputChoice: var(--onSurface_Medium);
  --chipBackgroundOutlineInputChoiceHover: var(--background10);
  --chipBackgroundOutlineInputChoiceHoverSelected: var(--base80);

  //DragDrop
  --chipBackgroundColorOutlineDragDrop: var(--background10);
  --chipBackgroundColorFlatDragDrop: var(--main80);

  /* CONTEXT MENU */
  --contextMenuBackground: var(--background10);
  --contextMenuDividerGroupColor: var(--onSurface_ExtraLow);
  --contextMenuItemColor: var(--onSurface_Medium);

  /* DIALOG */
  --dialogCardBackgroundColor: var(--background);
  --dialogCardSuccessColor: var(--success60);

  /*FORM FIELD*/
  --formFieldBackgroundColor: var(--background);
  --formFieldSuccessColor: var(--success60);
  --formFieldBorderColor: var(--onSurface_ExtraLow);
  --formFieldBorderColorHover: var(--onSurface_Medium);
  --formFieldDisabledBackgroundColor: var(--base10);

  /* IMAGE */
  --imageActionsContainerBackground: var(--onSurface_Medium);
  --imageButtonColor: var(--onColorSurface_High);
  --imageNavigationButtonBackground: var(--onSurface_Low);
  --imageNavigationButtonBackgroundHoverActive: var(--onSurface_Medium);
  --imageNavigationButtonColor: var(--onColorSurface_Medium);
  --imageNavigationButtonColorDisabled: var(--onColorSurface_Low);
  --imageNavigationButtonColorActive: var(--onColorSurface_High);
  --imageFooterTitleColor: var(--onColorSurface_High);
  --imageHeaderCounterColor: var(--onColorSurface_Medium);
  --imageFooterDescriptionColor: var(--onColorSurface_Medium);

  /* HEADER */
  --headerBackground: var(--background);
  --headerSecondaryBackground: var(--background10);
  --headerButtonDefaultColor: var(--onSurface_Medium);
  --headerButtonDefaultBackgroundColor: var(--background10);
  --headerButtonDefaultDisableBackgroundColor: var(--background10);
  --headerButtonDefaultHoverBackgroundColor: var(--background10);
  --headerSearchInputBackground: var(--background);
  --headerBoxShadow: inset 0px -1px 0px var(--onSurface_ExtraLow);
  --headerLogo: var(--light_imagotipo);

  /* PROGRESS BAR */
  --progressBarEmptyBackgroundColor: var(--onSurface_ExtraLow);

  /* RADIOBUTTON */
  --radioButtonBackground: var(--onColorSurface_High);
  --radioButtonHoverColor: var(--main80);
  --radioButtonBorderColor: var(--onSurface_Low);

  /* KEYBOARD */
  --virtualKeyboardContainerBackground: var(--background10);
  --virtualKeyboardDefaultKeyBackground: var(--background);
  --virtualKeyboardMainKeyBackground: var(--success05);
  --virtualKeyboardMainKeyColor: var(--onSurface_High);
  --virtualKeyboardDestructiveKeyColor: var(--alert);
  --virtualKeyboardBaseKeyBackground: var(--background20);
  --virtualKeyboardDefaultKeyBackgroundActive: var(--background20);
  --virtualKeyboardBaseKeyBackgroundActive: var(--background30);
  --virtualKeyboardPopupBackground: var(--background20);
  --virtualKeyboardPopupKeyBackground: var(--background10);

  /* SEARCH */
  --searchPanelBackground: var(--background);
  --searchOptionColor: var(--onSurface_Medium);

  /* SPINNER */
  --spinnerWhiteBackgroundColor: var(--onColorSurface_High);

  --spinnerWhiteBackdropBackground: var(--onSurface_Low);

  --spinnerWhiteMessageColor: var(--onColorSurface_High);

  /* MENU */
  --menuVerticalBackground: var(--background);
  --menuHorizontalItemBackgroundHover: var(--main80);
  --menuHorizontalItemBackgroundSelected: var(--main90);

  --menuSideItemBackgroundHover: var(--main80);

  --menuDividerBackground: var(--onSurface_ExtraLow);

  /* PAGINATOR */
  --paginatorBackground: var(--background10);
  --paginatorIconColor: var(--onSurface_Medium);
  --paginatorNumberedIconColor: var(--onSurface_Medium);
  --paginatorNumberedTextColor: var(--onSurface_Medium);
  --paginatorNumberedEllipsisColor: var(--onSurface_Low);
  --paginatorNumberedBarItemColor: var(--onSurface_Medium);

  /* SNACKBAR */
  --snackbarBackgroundColor: var(--base80);

  /* NUMBER INPUT */
  --numberInputBorderColor: var(--onSurface_ExtraLow);
  --numberInputDisabledBackgroundColor: var(--base10);
  --numberInputHoverColor: var(--onSurface_Medium);

  /* COLOR PICKER */
  --colorpickerInputBackgroundColor: var(--background);
  --colorpickerInputBorderColor: var(--onSurface_ExtraLow);
  --colorpickerInputBorderColorHover: var(--onSurface_Medium);
  --colorpickerInputDisabledBackgroundColor: var(--base10);

  --colorpickerBoxBorder: var(--onSurface_Medium);
  --colorpickerBoxBlackIcon: var(--onSurface_High);
  --colorpickerBoxWhiteIcon: var(--onColorSurface_High);

  --colorpickerPanelBackground: var(--background10);

  --colorpickerSelectorSliderCursorBorder: var(--onColorSurface_High);
  --colorpickerSelectorCursorBorder: var(--onColorSurface_High);
  --colorpickerSelectorInputBorder: var(--onSurface_ExtraLow);
  --colorpickerSelectorInputBackground: var(--background);
  --colorpickerSelectorInputBorderHover: var(--onSurface_Medium);

  --colorpickerButtonBackground: var(--background10);
  --colorpickerButtonBackgroundHover: var(--background20);
  --colorpickerButtonBackgroundDisabled: var(--background20);

  /* HTML EDITOR */
  --htmlEditorbackground: var(--background);
  --htmlEditorBorderColor: var(--onSurface_ExtraLow);
  --htmlEditorTablePopupBackground: var(--background10);
  --htmlEditorTableToolbarBackground: var(--background10);
  --htmlEditorTableBorderColor: var(--onSurface_ExtraLow);
  --htmlEditorTableHeaderBackground: var(--background20);
  --htmlEditorHyperLinkPopupBackground: var(--background);

  /* LIST */
  --listBackgroundColor: var(--background);
  --listItemBackgroundColor: var(--background);
  --listItemColorBarBackground: var(--warning80);
  --listDividerBackgroundColor: var(--onSurface_ExtraLow);
  --listItemSelected: var(--accent05);
  --listItemBackgroundColorHover: var(--base10);
  --listItemContentColor: var(--onSurface_Medium);

  /* SLIDER */
  --sliderInputBackgroundColor: var(--background);
  --sliderInputDisabledBackgroundColor: var(--base10);
  --sliderInputBorderColor: var(--onSurface_ExtraLow);
  --sliderMarkColor: var(--onSurface_High);

  --sliderPressedTrackBackgroundColor: var(--main90);
  --sliderHoverTrackBackgroundColor: var(--main80);

  /* SWITCH */
  --switchCheckedBackgroundColorHover: var(--main80);
  --switchDisabledToggleBallBackgroundColor: var(--onColorSurface_Medium);

  /* TILE */
  --tileBackgroundColor: var(--background10);

  /* TOOLTIP */
  --tooltipDefaultBackgroundColor: var(--background10);
  --tooltipActionBackgroundColor: var(--base80);

  /* TREE */
  --treeNodeSelectedBackgroundColor: var(--accent05);

  /* TABS */
  --tabLabelColor: var(--main80);
  --tabContainerBackgroundColor: var(--background);
  --tabLabelSeparatorBackgroundColor: var(--onSurface_ExtraLow);
  --tabLabelMainBackgroundHover: var(--main80);

  --tabUnderlineBackgroundColor: var(--onSurface_ExtraLow);
  --tabBodyBorderColor: var(--onSurface_ExtraLow);

  /* STEPPER */
  --stepperHorizontalLineBackgroundColor: var(--onSurface_ExtraLow);
  --stepperDeterminateBaseBackgroundColor: var(--onColorSurface_High);
  --stepperNumberColor: var(--onSurface_Medium);
  --stepperDeterminateBaseColor: var(--onColorSurface_Medium);

  /* UPLOADER */
  --uploaderInlineDisabledButtonColor: var(--onSurface_Low);

  /* CAROUSEL */
  --carouselButtonHoverColor: var(--main90);
  --carouselButtonEnabledColor: var(--main);
  --carouselDotDefaultColor: var(--range11_30);
  --carouselDotActiveColor: var(--range11_60);

  /* SIDENAV */
  --sidenavBackground: var(--background);
  --sidenavBackdropBackground: var(--onSurface_Low);

  /* SELECT */
  --selectBorderColor: var(--onSurface_ExtraLow);
  --selectBorderColorHover: var(--onSurface_Medium);
  --selectBackgroundColor: var(--background);

  --selectDisabledBackgroundColor: var(--base10);

  --selectWithoutFormFieldDisabledBorderColor: var(--onSurface_ExtraLow);
  --selectPanelBackground: var(--background);
  --selectPanelBorderColor: var(--onSurface_ExtraLow);

  --selectSearchBackground: var(--background10);
  --selectSearchColor: var(--onSurface_Medium);

  --selectOptionBorderColor: var(--background);
  --selectOptionBackground: var(--background);
  --selectOptionColor: var(--onSurface_Medium);

  /* SELECTION SEARCH */
  --selectionSearchBorderColor: var(--onSurface_ExtraLow);
  --selectionSearchBorderColorHover: var(--onSurface_Medium);
  --selectionSearchBackgroundColor: var(--background);

  --selectionSearchDisabledBackgroundColor: var(--base10);
  --selectionSearchWithoutFormFieldDisabledBorderColor: var(--onSurface_ExtraLow);

  --selectionSearchPanelBackground: var(--background);
  --selectionSearchPanelBorderColor: var(--onSurface_ExtraLow);

  --selectionSearchOptionBorderColor: var(--background);
  --selectionSearchOptionBackground: var(--background);
  --selectionSearchOptionColor: var(--onSurface_Medium);

  /* CHARTS */
  --chartsLegendTextFill: var(--onSurface_Medium);
  --chartsGridColor: var(--onSurface_ExtraLow);
  --chartsAuxiliaryTextFill: var(--onSurface_Low);
  --chartAxisLabelColor: var(--onSurface_Medium);
  --chartLabelInsideColor: var(--onSurface_High);

  /* TABLE */
  --tableBorder: var(--onSurface_ExtraLow);
  --tableTrBorder: var(--onSurface_Low);
  --tableTrHeaderBackground: var(--background20);
  --tableTrFooterBackground: var(--background20);
  --tableTdEvenBackground: var(--background10);

  --tableHeaderFilterTriangleBorder: var(--info70);

  --tableManageColumnsContainerBackground: var(--background);
  --tableManageColumnsItemBorder: var(--onSurface_ExtraLow);
  --tableManageColumnsItemBackground: var(--background);
  --tableManageColumnsItemPositionBackground: var(--background10);
  --tableManageColumnsItemPositionColor: var(--onSurface_Medium);

  --tableTopbarButtonBorder: var(--onColorSurface_High);
  --tableTopbarButtonHoverBackground: var(--onSurface_ExtraLow);
  --tableTopbarButtonActiveBackground: var(--onColorSurface_Low);
  --tableTopbarButtonDisabledColor: var(--onSurface_ExtraLow);

  --tableCellEditableHoverCell: var(--onSurface_Low);
  --tableCellEditableInvalidColor: var(--alert);
  --tableCellEditableInvalidBackground: var(--alert05);
  --tableCellEditableNotSavedColor: var(--warning90);
  --tableCellEditableNotSavedBackground: var(--warning10);
  --tableCellEditableSuccessColor: var(--success60);
  --tableCellEditableSuccessBackground: var(--success05);
  --tableCellEditableNumberInputIconColor: var(--base);

  /* DATEPICKER */
  --datepickerCalendarPopupBackground: var(--background);
  --datepickerDividerBackground: var(--onSurface_ExtraLow);
  --datepickerDividerColor: var(--onSurface_ExtraLow);
  --datepickerInputDisabledBackground: var(--base10);
  --datepickerInputDisabledBorder: var(--onSurface_ExtraLow);
  --datepickerActionSelectedColor: var(--main90);

  --datepickerMonthYearCellColor: var(--onSurface_Medium);
  --datepickerMonthYearCellBorder: var(--onSurface_Medium);
  --datepickerMonthYearCellColorHover: var(--onSurface_Medium);
  --datepickerMonthYearCellRangeInColor: var(--onSurface_Medium);
  --datepickerMonthYearCellRangeStartEndDisabledColorSelected: var(--onColorSurface_Low);

  --datepickerCalendarMonthNameColor: var(--onSurface_Medium);
  --datepickerWeekendBackground: var(--base10);
  --datepickerDayBackgroundHover: var(--range07_20);
  --datepickerDayColorHover: var(--onSurface_High);
  --datepickerDayCellBoundingDisabledColor: var(--onSurface_ExtraLow);
  --datepickerDayCellBoundingColor: var(--onSurface_Low);
  --datepickerDayCellSelectedColor: var(--onColorSurface_High);
  --datepickerDayCellSelectedBackground: var(--accent);
  --datepickerDayCellSelectedBoundingColor: var(--onColorSurface_Medium);
  --datepickerDayCellSelectedBoundingBackground: var(--accent40);
  --datepickerDayCellTodayBorder: var(--onSurface_Medium);
  --datepickerDayCellTodayBoundingBorder: var(--onSurface_Low);
  --datepickerDayCellRangeInColor: var(--onSurface_High);
  --datepickerDayCellRangeInBackground: var(--accent10);
  --datepickerDayCellRangeInBoundingColor: var(--onSurface_Low);
  --datepickerDayCellRangeInBoundingBackground: var(--accent05);
  --datepickerDayCellRangeStartEndColor: var(--onColorSurface_High);
  --datepickerDayCellRangeStartEndBackground: var(--accent);
  --datepickerDayCellRangeDisableWeekColorSelected: var(--onSurface_ExtraLow);
  --datepickerDayNameBorder: var(--onSurface_ExtraLow);
  --datepickerDayNameColor: var(--onSurface_Medium);

  --datepickerViewModeColor: var(--onSurface_Medium);
  --datepickerInputPickerComponentStylesBackground: var(--background);
  --datepickerInputPickerComponentStylesBorder: var(--onSurface_ExtraLow);
  --datepickerInputPickerComponentStylesBorderHover: var(--onSurface_Medium);

  /* WEEK SELECTOR RANGE */
  --datepickerWeekSelectorRangeSelectStartEndInHover: var(--main80);
  --datepickerWeekSelectorInRangeHover: var(--range11_30);
  --datepickerWeekSelectorInRangeBackgroundHover: var(--range11_30);
  --datepickerWeekSelectorRangeStartEnd: var(--main90);
  --datepickerWeekSelectorRangeStartBackground: var(--main10);
  --datepickerWeekSelectorInRangeDisabled: var(--onSurface_ExtraLow);
  --datepickerWeekSelectorInRangeHoverBackground: var(--main10);
  --datepickerWeekSelectorInRangeHoverColor: var(--main);

  /* WEEK SELECTOR RANGE DAYS */
  --datepickerBoundingDay: var(--onSurface_Low);
  --datepickerDayDisable: var(--onSurface_ExtraLow);

  /* TIMEPICKER */

  --timePickerInputBackgroundColor: var(--background);
  --timePickerInputBorderColor: var(--onSurface_ExtraLow);
  --timePickerInputHoverBorderColor: var(--onSurface_Medium);
  --timepickerDisabledBackgroundColor: var(--base10);
  --timePickerCarouselBackgroundColor: var(--background);
  --timePickerCarouselBorderColor: var(--onSurface_ExtraLow);
  --timePickerDisabledBorderColor: var(--onSurface_ExtraLow);
  --timePickerSelectOptionColor: var(--onSurface_Medium);

  /* MAPS */
  --mapLegendBackgroundColor: var(--background10);
  --mapLegendTextColor: var(--onSurface_Medium);
  --mapDefaultStyleColor: var(--background10);
  --mapPolygonBorderColor: var(range11_50);
  --mapZoomButtonHoverBackgroundColor: var(--base80);

  /* POPOVER */
  --popoverDefaultBackgroundColor: var(--background10);
  --popoverAlternativeBackgroundColor: var(--base80);

  /* CALENDAR */
  --calendarBorderColor: var(--onSurface_ExtraLow);
  --calendarBackgroundColorHover: var(--accent10);
  --calendarOverlayModePanelBackground: var(--background);

  /* CALENDAR: Month  */
  --calendarDividerBackgroundColor: var(--onSurface_ExtraLow);
  --calendarDividerLineBackgroundColor: var(--onSurface_ExtraLow);
  --calendarWeekendBackgroundColor: var(--base10);
  --calendarMonthCellDisabledBoundingColor: var(--onSurface_ExtraLow);
  --calendarMonthEventDisabledBackground: var(--base10);
  --calendarMonthEventDisabledColor: var(--onSurface_ExtraLow);

  /* CALENDAR: Week */
  --calendarWeekWeekendBackgroundColor: var(--base10);
  --calendarWeekCellDisabledColor: var(--onSurface_ExtraLow);

  /* CALENDAR: Year */
  --calendarYearWeekendBackgroundColor: var(--background10);
  --calendarYearBorderBackgroundColor: var(--onSurface_ExtraLow);
  --calendarNavigationHeaderColor: var(--onSurface_Medium);
  --calendarViewYearMonthNameColor: var(--base);

  /* SKELETON */
  --skeletonBackgroundColor: rgba(185, 191, 199, 0.2);
  --skeletonAnimationBackgroundColor: rgba(80, 86, 91, 0.2);
}
