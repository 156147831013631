$grid-breakpoints: (
  // Mobile en portrait o landscape.
  xs: 0,
  // Tablet en portrait.
  sm: 48em,
  // Tablets en landscape como iPad Pro en portrait.
  md: 64em,
  // iPad Pro en landscape y todos los portátiles pequeños.
  lg: 85em,
  // Todos los desktop y pantallas grandes.
  xl: 120em,
  // Televisiones 4K
  xxl: 227.5em
);

$container-max-widths: (
  sm: 45em,
  md: 61em,
  lg: 82em,
  xl: 117em,
  xxl: 224.5em
);

$smallScreen: map-get($grid-breakpoints, sm);
$mediumScreen: map-get($grid-breakpoints, md);
$largeScreen: map-get($grid-breakpoints, lg);
$xLargeScreen: map-get($grid-breakpoints, xl);
$xxLargeScreen: map-get($grid-breakpoints, xxl);

$smallMaxWidth: map-get($container-max-widths, sm);
$mediumMaxWidth: map-get($container-max-widths, md);
$largeMaxWidth: map-get($container-max-widths, lg);
$xLargeMaxWidth: map-get($container-max-widths, xl);
$xxLargeMaxWidth: map-get($container-max-widths, xxl);
