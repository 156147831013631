@use 'core-variables/base-theme-core-variables' as core-variables;

/* Light Mint (default) Theme */

.base-theme {
  @include core-variables.core-theme-variables;

  --light_imagotipo: #009342;

  /* Main */
  --main: #0f803e;
  --main80: #0d6e35;
  --main90: #0b5b2c;
  --main10: #daf2e0;
}
