body {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background);
  color: var(--bodyColor);
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
  background: var(--onSurface_Low);
}

.m-highlight-search {
  color: var(--accent);
}
