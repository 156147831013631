@use '../components-variables/dark-mint-theme.components' as components;

/* Dark base theme variables */
@mixin core-theme-variables() {
  // NEW VARIABLES

  /*Imagotipo ICM variable */
  --dark_imagotipo: #ffffff;
  /* Main */
  --main05: #094325;
  --main: #49cd8b;
  --main90: #6ddd9f;
  --main100: #8febb2;

  /* Base */
  --base05: #13171a;
  --base10: #242a2e;
  --base20: #373d42;
  --base30: #50565b;
  --base60: #9fa5ad;
  --base: #d5d9e0;
  --base90: #f2f4f8;
  --base100: #f8f9fb;

  /* Accent */
  --accent10: #5611ae;
  --accent40: #9147ed;
  --accent60: #b278fb;
  --accent90: #dbbdff;
  --accent: #cea7fe;

  /* On Surface */
  $surface: #ffffff;
  --onSurface_High: #{rgba($surface, 0.87)};
  --onSurface_Medium: #{rgba($surface, 0.6)};
  --onSurface_Low: #{rgba($surface, 0.38)};
  --onSurface_ExtraLow: #{rgba($surface, 0.15)};

  /* On Color Surface */
  $colorSurface: #000000;
  --onColorSurface_High: #{rgba($colorSurface, 1)};
  --onColorSurface_Medium: #{rgba($colorSurface, 0.74)};
  --onColorSurface_Low: #{rgba($colorSurface, 0.38)};

  /* Info */
  --info10: #004188;
  --info80: #7cbefb;
  --info90: #98cffe;

  /* Warning */

  --warning10: #732c01;
  --warning70: #f98a18;
  --warning80: #ffa33c;
  --warning90: #ffbd67;
  --warning100: #fdd392;

  /* Alert */
  --alert05: #7b0115;
  --alert10: #880117;
  --alert20: #a5011b;
  --alert30: #c10d24;
  --alert40: #d92537;
  --alert: #f66368;
  --alert70: #fe8082;
  --alert80: #ff9d9e;
  --alert90: #ffb7b7;

  /* Success */
  --success05: #094325;
  --success10: #084b29;
  --success20: #025d34;
  --success40: #07a862;
  --success100: #8febb2;
  --success90: #6ddd9f;

  /* Background */
  --background: #13171a;
  --background_L1: #24272a;
  --background_L2: #2b2e31;
  --background_L3: #323538;
  --background_L4: #393c3f;

  /* Range */
  /* Black */
  --range01: #000000;
  /* White */
  --range02: #ffffff;
  /* Yellow */
  --range03_05: #4d3801;
  --range03_10: #563f01;
  --range03_20: #674e01;
  --range03_30: #7a5d01;
  --range03_40: #8d6d01;
  --range03_50: #a17f01;
  --range03_60: #b49101;
  --range03_70: #c7a301;
  --range03_80: #d8b601;
  --range03_90: #e9c801;
  --range03_100: #f7d905;

  /* Orange */
  --range04_05: #682701;
  --range04_10: #732c01;
  --range04_20: #8a3801;
  --range04_30: #a24501;
  --range04_40: #ba5301;
  --range04_50: #d26301;
  --range04_60: #e87501;
  --range04_70: #f98a18;
  --range04_80: #ffa33c;
  --range04_90: #ffbd67;
  --range04_100: #fdd392;

  /* Red */
  /* Red */
  --range05_05: #7b0115;
  --range05_10: #880117;
  --range05_20: #a5011b;
  --range05_30: #c10d24;
  --range05_40: #d92537;
  --range05_50: #ea454f;
  --range05_60: #f66368;
  --range05_70: #fe8082;
  --range05_80: #ff9d9e;
  --range05_90: #ffb4d0;
  --range05_100: #ffcdcd;

  /* Magenta */
  --range06_05: #78013c;
  --range06_10: #850142;
  --range06_20: #a1014f;
  --range06_30: #ba175e;
  --range06_40: #d12c73;
  --range06_50: #e3468a;
  --range06_60: #f1629d;
  --range06_70: #fc7dae;
  --range06_80: #ff99c0;
  --range06_90: #ffb4d0;
  --range06_100: #ffcbde;

  /* Purple */
  --range07_05: #4d109e;
  --range07_10: #5611ae;
  --range07_20: #6a1ec9;
  --range07_30: #7e32df;
  --range07_40: #9147ed;
  --range07_50: #a25ff7;
  --range07_60: #b278fb;
  --range07_70: #c090fd;
  --range07_80: #cea7fe;
  --range07_90: #dbbdff;
  --range07_100: #e6d0ff;

  /* Blue */
  --range08_05: #003a7a;
  --range08_10: #004188;
  --range08_20: #004fa7;
  --range08_30: #005dc9;
  --range08_40: #066de8;
  --range08_50: #1d81f6;
  --range08_60: #4097f4;
  --range08_70: #5eabf8;
  --range08_80: #7cbefb;
  --range08_90: #98cffe;
  --range08_100: #b3dfff;

  /* Cyan */
  --range09_05: #003f63;
  --range09_10: #00466d;
  --range09_20: #005781;
  --range09_30: #006894;
  --range09_40: #007aa8;
  --range09_50: #008dbb;
  --range09_60: #04a1ce;
  --range09_70: #17b5de;
  --range09_80: #39c8eb;
  --range09_90: #60d9f4;
  --range09_100: #86e7fb;

  /* Aqua */
  --range10_05: #124240;
  --range10_10: #104a47;
  --range10_20: #035c59;
  --range10_30: #006d69;
  --range10_40: #00807a;
  --range10_50: #00938d;
  --range10_60: #00a6a0;
  --range10_70: #1abab3;
  --range10_80: #42cbc4;
  --range10_90: #66dbd4;
  --range10_100: #8be9e2;

  /* Green */
  --range11_05: #094325;
  --range11_10: #084b29;
  --range11_20: #025d34;
  --range11_30: #006f3c;
  --range11_40: #07a862;
  --range11_50: #008244;
  --range11_60: #07a862;
  --range11_70: #24bc76;
  --range11_80: #49cd8b;
  --range11_90: #6ddd9f;
  --range11_100: #8febb2;

  /* Grey */
  --range12_05: #13171a;
  --range12_10: #242a2e;
  --range12_20: #373d42;
  --range12_30: #50565b;
  --range12_40: #697077;
  --range12_50: #868d95;
  --range12_60: #9fa5ad;
  --range12_70: #b9bfc7;
  --range12_80: #d5d9e0;
  --range12_90: #f2f4f8;
  --range12_100: #f8f9fb;

  /*Shadows*/
  --shadowL1: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --shadowL2: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --shadowL3: 0px 5px 9px 4px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  --shadowL4: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.3);

  // OLD VARIABLES

  /* Interactive */
  --interactive01: var(--main);
  --interactive02: var(--base);
  --interactive03: var(--accent);

  /* Destructive */
  --destructive01: var(--alert);

  /* State */
  --active01: var(--main100);
  --active02: var(--base100);
  --active03: var(--alert90);
  --activeUi: var(--onSurface_ExtraLow);

  --hover01: var(--main90);
  --hover02: var(--onSurface_High);
  --hover03: var(--base30);
  --hover04: var(--base10);
  --hover05: var(--alert80);

  --disabled01: var(--base10);
  --disabled02: var(--onSurface_ExtraLow);
  --disabled03: var(--base30);
  --disabled04: var(--onSurface_Low);

  /* Support */
  --support01: var(--alert80);
  --support02: var(--success100);
  --support03: var(--warning80);
  --support04: var(--info90);
  --support05: var(--accent);

  --supportBack01: var(--alert05);
  --supportBack02: var(--success05);
  --supportBack03: var(--warning10);
  --supportBack04: var(--info10);
  --supportBack05: var(--accent10);
  --supportBack011: var(--alert10);
  --supportBack012: var(--alert30);
  --supportBack013: var(--alert40);
  --supportBack022: var(--success20);
  --supportBack023: var(--success40);
  --supportBack041: var(--info05);
  --supportBack051: var(--accent40);
  // TODO: --supportBack052: var(--accent60);

  /* Text */
  --text01: var(--onSurface_High);
  --text02: var(--onSurface_High);
  --text03: var(--onSurface_Medium);
  --text04: var(--base60);
  --text05: var(--onColorSurface_High);
  --text06: var(--main);
  --textError: var(--alert);
  --textWarning: var(--warning70);

  /* Icon */
  --icon01: var(--onSurface_High);
  --icon02: var(--onSurface_High);
  --icon03: var(--onSurface_Medium);
  --icon04: var(--base60);
  --icon05: var(--onColorSurface_High);

  /* UI */
  --ui01: var(--onSurface_High);
  --ui02: var(--onSurface_Medium);
  --ui03: var(--base60);
  --ui04: var(--base10);
  --ui05: var(--background);
  --ui06: var(--base90);

  /* Field */
  --field01: var(--background);
  --field02: var(--background_L1);
  --field03: var(--main05);
  --field04: var(--background_L2);
  --field05: var(--background_L3);
  --field06: var(--background_L4);

  /* Highlight */
  --highlight01: var(--main90);
  --highlight02: var(--main05);

  /* Shadows */
  // --dropShadow01: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // --dropShadow02: 0px -1px 4px rgba(0, 0, 0, 0.25);
  // --dropShadow03: 0px 0px 4px rgba(0, 0, 0, 0.25);
  --lightShadow01: 0px 1px 4px 0px rgba(0, 0, 0, 0.8);
  // --lightShadow02: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  // --popUpShadow01: 0 4px 20px rgba(0, 0, 0, 0.25);
  // --popUpShadow02: -4px 0px 8px rgba(0, 0, 0, 0.25);
  // --popUpShadow03: 4px 0px 8px rgba(0, 0, 0, 0.25);

  /* Weight */
  --fontWeightLight: 300;
  --fontWeightSemibold: 600;
  --fontWeightRegular: 400;

  /* Font */
  --fontSizeHelper: 0.75rem; // 12px
  --fontSizeCaption: 0.75rem; // 12px
  --fontSizeOverline: 0.75rem; // 12px
  --fontSizeBody01: 1.125rem; // 18px
  --fontSizeBody02: 1rem; // 16px
  --fontSizeBody03: 0.875rem; // 14px
  --fontSizeHeading01: 2.625rem; // 42px
  --fontSizeHeading02: 2.25rem; // 36px
  --fontSizeHeading03: 2rem; // 32px
  --fontSizeHeading04: 1.75rem; // 28px
  --fontSizeHeading05: 1.5rem; // 24px
  --fontSizeHeading06: 1.25rem; // 20px
  --fontSizeDisplay01: 3.75rem; // 60px
  --fontSizeDisplay02: 3.375rem; // 54px
  --fontSizeDisplay03: 3rem; // 48px

  /* LineHeight */
  --lineHeightHelper: 1rem; // 16px
  --lineHeightCaption: 1rem; // 16px
  --lineHeightOverline: 1rem; // 16px
  --lineHeightBody01: 1.5rem; // 24px
  --lineHeightBody02: 1.375rem; // 22px
  --lineHeightBody03: 1.188rem; // 19px
  --lineHeightHeading01: 3.563rem; // 57px
  --lineHeightHeading02: 3.063rem; // 49px
  --lineHeightHeading03: 2.688rem; // 43px
  --lineHeightHeading04: 2.375rem; // 38px
  --lineHeightHeading05: 2.063rem; // 33px
  --lineHeightHeading06: 1.688rem; // 27px
  --lineHeightDisplay01: 5.125rem; // 82px
  --lineHeightDisplay02: 4.563rem; // 73px
  --lineHeightDisplay03: 4.063rem; // 65px

  @include components.component-variables;
}
