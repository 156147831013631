@use 'core-variables/dark-base-theme-core-variables' as core-variables;

/* Dark Mint Theme */
.dark-mint-theme {
  @include core-variables.core-theme-variables;
  --dark_imagotipo: #ffffff;

  /* Main */
  --main05: #094325;
  --main: #49cd8b;
  --main90: #6ddd9f;
  --main100: #8febb2;
}
